import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from '@core/services/breadcrumbs.service';
import { AgencyService } from '@core/services/agency.service';
import { NotificationService } from '@shared/notification/notification.service';
import { Modes } from '@models/modes';
import { Agency } from '@models/agency';
import {
  CustomerType as CustomerTypes,
  ElementId,
  FEATURE,
} from '@storykit/constants';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  lastValueFrom,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { environment } from '@environment';

import {
  filter,
  first,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormRecord,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { AgencyNotification } from '@shared/notification/agency-messages';
import { ConfirmDialogComponent } from '@views/partials/confirm-dialog/confirm-dialog.component';
import { ClientService } from '@core/services/client.service';
import { Client } from '@models/client';
import { MatTable } from '@angular/material/table';
import { ObservableCacheService } from '@core/services/observable-cache.service';
import { CwsService } from '@core/services/cws.service';
import { FreeTrialForm } from '@shared/free-trial-form/free-trial-form.component';
import { UserListDataSource } from '@views/user/user-list/user-list.dataSource';
import { DOCUMENT } from '@angular/common';
import { FeatureService } from '@storykit/ui-components';

const DEFAULT_USERS_AMOUNT_PER_CUSTOMER_TYPE = {
  [CustomerTypes.LITE]: 1,
  [CustomerTypes.PRO]: 2,
  [CustomerTypes.ENTERPRISE]: 9999,
  /** @deprecated - freemium is deprecated */
  [CustomerTypes.FREEMIUM]: 10,
  /** @deprecated - starter is deprecated */
  [CustomerTypes.STARTER]: 2,
  /** @deprecated - standard is deprecated */
  [CustomerTypes.STANDARD]: 5,
};

const DEFAULT_RENDERS_AMOUNT_PER_CUSTOMER_TYPE = {
  [CustomerTypes.LITE]: 9999,
  [CustomerTypes.PRO]: 9999,
  [CustomerTypes.ENTERPRISE]: 9999,
  /** @deprecated - freemium is deprecated */
  [CustomerTypes.FREEMIUM]: 10,
  /** @deprecated - starter is deprecated */
  [CustomerTypes.STARTER]: 9999,
  /** @deprecated - standard is deprecated */
  [CustomerTypes.STANDARD]: 9999,
};

const DEFAULT_MAX_CUSTOM_FONTS_PER_CUSTOMER_TYPE = {
  [CustomerTypes.LITE]: 1,
  [CustomerTypes.PRO]: 5,
  [CustomerTypes.ENTERPRISE]: 999,
  /** @deprecated - freemium is deprecated */
  [CustomerTypes.FREEMIUM]: 1,
  /** @deprecated - starter is deprecated */
  [CustomerTypes.STARTER]: 3,
  /** @deprecated - standard is deprecated */
  [CustomerTypes.STANDARD]: 5,
};

const DEFAULT_CUSTOMER_ADMIN_PER_CUSTOMER_TYPE = {
  [CustomerTypes.LITE]: true,
  [CustomerTypes.PRO]: true,
  [CustomerTypes.ENTERPRISE]: false,
  /** @deprecated - freemium is deprecated */
  [CustomerTypes.FREEMIUM]: true,
  /** @deprecated - starter is deprecated */
  [CustomerTypes.STARTER]: true,
  /** @deprecated - standard is deprecated */
  [CustomerTypes.STANDARD]: true,
};

interface ClientTableEntry {
  client: Client;
  deleted: boolean;
  new: boolean;
  updated: boolean;
}

@Component({
  selector: 'app-agency',
  templateUrl: './agency.component.html',
  styleUrls: ['./agency.component.scss'],
})
export class AgencyComponent implements OnInit, OnDestroy {
  @ViewChild(MatTable)
  clientTable!: MatTable<ClientTableEntry>;

  readonly createUrl = environment.create.link;

  public elementId = ElementId;

  customerTypeOptions: kit.CustomerType[] = [
    CustomerTypes.PRO,
    CustomerTypes.ENTERPRISE,
    /** @deprecated - freemium is deprecated */
    CustomerTypes.FREEMIUM,
  ];

  modes = Modes;

  agencyLink = '';

  hasUnsavedClientChanges = false;
  hasUnsavedPremiumChanges = false;
  hasUnsavedFreeTrialChanges = false;
  hasUnsavedFreemiumChanges = false;
  hasUnsavedFontChanges = false;
  hasUnsavedFormChanges = false;

  formLoading = false;

  hasExpiredTrial = false;

  usersDataSource = new UserListDataSource(this.cwsService);

  mode$ = this.route.data.pipe(map(({ mode }) => mode as Modes));

  clientFormFields = {
    hubspotCompanyId: 'hubspotCompanyId',
  };

  agencyId$ = this.route.params.pipe(
    map(({ agencyId }) => (agencyId ? String(agencyId) : undefined))
  );

  agency$ = this.agencyId$.pipe(
    switchMap((agencyId) =>
      agencyId ? this.agencyService.getAgency(agencyId) : of(undefined)
    ),
    shareReplay(1)
  );

  loading$ = this.agency$.pipe(
    map(() => false),
    startWith(true)
  );

  fonts$ = this.agencyId$.pipe(
    switchMap((agencyId) =>
      agencyId ? this.cwsService.listFonts(agencyId) : of(undefined)
    ),
    shareReplay(1)
  );

  agencyForm = this.formBuilder.group(
    {
      name: ['', Validators.required],
      hubspotCompanyId: ['', [Validators.required]],
      active: [true, Validators.required],
      customerType: [CustomerTypes.PRO, Validators.required],
      freeTrial: [false, Validators.required],
      freeTrialExpirationDate: [null],
      featureSettings: this.formBuilder.group({
        videoblocks: this.formBuilder.group({
          value: [
            {
              value: true,
              disabled: true,
            },
            Validators.required,
          ],
        }),
        getty: this.formBuilder.group({
          value: [
            {
              value: false,
              disabled: false,
            },
            Validators.required,
          ],
        }),
        premiumMusic: this.formBuilder.group({
          value: [
            {
              value: true,
              disabled: false,
            },
            Validators.required,
          ],
        }),
        scriptCreators: this.formBuilder.group({
          value: [
            {
              value: true,
              disabled: false,
            },
            Validators.required,
          ],
        }),
        maxUsersAmount: this.formBuilder.group({
          value: [
            {
              value: DEFAULT_USERS_AMOUNT_PER_CUSTOMER_TYPE[CustomerTypes.PRO],
              disabled: false,
            },
          ],
        }),
        renderCredits: this.formBuilder.group({
          value: [
            {
              value:
                DEFAULT_RENDERS_AMOUNT_PER_CUSTOMER_TYPE[CustomerTypes.PRO],
              disabled: false,
            },
          ],
        }),
        maxCustomFont: this.formBuilder.group({
          value:
            DEFAULT_MAX_CUSTOM_FONTS_PER_CUSTOMER_TYPE[CustomerTypes.FREEMIUM],
          disabled: false,
        }),
        customerAdmin: this.formBuilder.group({
          value:
            DEFAULT_CUSTOMER_ADMIN_PER_CUSTOMER_TYPE[CustomerTypes.FREEMIUM],
          disabled: false,
        }),
      }),
      settings: this.formBuilder.group({
        scriptCreatorLanguageInstruction: [''],
      }),
    },
    {
      validators: [this.freeTrialExpirationDateValidator],
    }
  );

  clientDataSource: ClientTableEntry[] = [];

  clientTableColumns = ['name', 'hubspotId', 'delete', 'link'];

  clientForm = this.formBuilder.group({
    name: ['', Validators.required],
  });

  clientDataForm = new FormRecord<FormControl<string>>({});

  /** @deprecated - freemium is deprecated */
  public isFreemiumAgency = false;

  public maxCustomFonts: number | undefined;

  clients$: Observable<Client[] | null>;

  public canEditLanguageInstruction$ =
    this.featureService.isFeatureEnabledSubject(
      FEATURE.CAN_EDIT_LANGUAGE_INSTRUCTION
    );

  private clientsSubject$ = new BehaviorSubject<Client[] | null>(null);

  private destroy$ = new Subject<void>();

  private breadcrumbs$ = combineLatest([this.agency$, this.mode$]).pipe(
    map(([agency, mode]) => {
      const name = mode === Modes.Add ? 'New Agency' : agency?.name || '';
      return [{ label: 'Agencies', link: '/agencies' }, { label: name }];
    }),
    startWith([{ label: 'Agencies', link: '/agencies' }, { label: '' }])
  );

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private agencyService: AgencyService,
    private breadcrumbsService: BreadcrumbsService,
    private formBuilder: FormBuilder,
    private notification: NotificationService,
    private dialog: MatDialog,
    private clientService: ClientService,
    private cdr: ChangeDetectorRef,
    private cache: ObservableCacheService,
    private cwsService: CwsService,
    private featureService: FeatureService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.breadcrumbs$
      .pipe(takeUntil(this.destroy$))
      .subscribe((breadcrumbs) => {
        this.breadcrumbsService.set(breadcrumbs);
      });
    this.initCustomerTypeOptions();
    this.clients$ = this.clientsSubject$.asObservable();
  }

  // use in template to conditionally show Freemium tab
  get isFreemiumTabVisible() {
    return this.isFreemiumAgency;
  }

  ngOnInit(): void {
    combineLatest([this.agencyId$, this.clients$])
      .pipe(filter(([agencyId, clients]) => !!agencyId && !!clients))
      .subscribe(([agencyId, clients]) => {
        this.agencyLink = `${this.createUrl}/${agencyId}`;

        this.usersDataSource.updateAccessIds([
          agencyId as string,
          ...(clients as Client[]).map((c) => c._id.toString()),
        ]);
      });

    this.agency$.subscribe((agency) =>
      agency ? this.setAgency(agency) : null
    );

    this.agencyForm
      .get('customerType')
      ?.valueChanges.pipe(
        withLatestFrom(this.agency$),
        takeUntil(this.destroy$)
      )
      .subscribe(([customerType, agency]) => {
        this.setPremiumValues(customerType);

        const isDefaultAgencyUsersAmount =
          agency &&
          agency?.featureAccess?.maxUsersAmount?.value ===
            DEFAULT_USERS_AMOUNT_PER_CUSTOMER_TYPE[
              customerType as kit.CustomerType
            ];

        if (!agency || isDefaultAgencyUsersAmount) {
          this.agencyForm.controls.featureSettings
            .get('maxUsersAmount')
            ?.patchValue({
              value:
                DEFAULT_USERS_AMOUNT_PER_CUSTOMER_TYPE[
                  customerType as kit.CustomerType
                ],
            });
        }

        if (customerType === CustomerTypes.FREEMIUM) {
          this.agencyForm.controls.featureSettings
            .get('renderCredits')
            ?.patchValue({
              value:
                DEFAULT_RENDERS_AMOUNT_PER_CUSTOMER_TYPE[
                  CustomerTypes.FREEMIUM
                ],
            });
          this.agencyForm.controls.featureSettings
            .get('maxUsersAmount')
            ?.patchValue({
              value:
                DEFAULT_USERS_AMOUNT_PER_CUSTOMER_TYPE[CustomerTypes.FREEMIUM],
            });
          this.agencyForm.controls.featureSettings
            .get('scriptCreators')
            ?.patchValue({ value: true });
          this.agencyForm.controls.featureSettings
            .get('premiumMusic')
            ?.patchValue({ value: false });
          this.agencyForm.controls.featureSettings
            .get('videoblocks')
            ?.patchValue({ value: false });
          this.agencyForm.controls.featureSettings
            .get('getty')
            ?.patchValue({ value: false });

          this.isFreemiumAgency = true;
        } else {
          this.isFreemiumAgency = false;
        }

        this.agencyForm.controls.featureSettings
          .get('customerAdmin')
          ?.patchValue({
            value:
              DEFAULT_CUSTOMER_ADMIN_PER_CUSTOMER_TYPE[
                customerType as kit.CustomerType
              ],
          });

        if (!agency || customerType === CustomerTypes.FREEMIUM) {
          if (this.agencyForm.controls.featureSettings?.get('maxCustomFont')) {
            this.agencyForm.get('featureSettings.maxCustomFont')?.patchValue({
              value:
                this.maxCustomFonts ||
                agency?.featureSettings?.maxCustomFont?.value ||
                DEFAULT_MAX_CUSTOM_FONTS_PER_CUSTOMER_TYPE[
                  CustomerTypes.FREEMIUM
                ],
            });
          } else {
            (this.agencyForm.get('featureSettings') as FormGroup)?.addControl(
              'maxCustomFont',
              this.formBuilder.group({
                value:
                  DEFAULT_MAX_CUSTOM_FONTS_PER_CUSTOMER_TYPE[
                    CustomerTypes.FREEMIUM
                  ],
                disabled: false,
              })
            );
          }
        }
      });

    this.agencyId$
      .pipe(
        takeUntil(this.destroy$),
        switchMap((agencyId) =>
          agencyId ? this.clientService.getClients(agencyId) : of([])
        )
      )
      .subscribe((clients) => {
        this.clientsSubject$.next(clients);

        // init clients data source
        if (clients && clients.length) {
          clients.forEach((client, i) => {
            this.clientDataSource.push({
              client,
              deleted: false,
              new: false,
              updated: false,
            });
            this.clientDataForm.addControl(
              this.clientFormFields.hubspotCompanyId + i,
              new FormControl(client.hubspotCompanyId, {
                validators: [Validators.required],
                nonNullable: true,
              })
            );
          });

          this.clientTable?.renderRows();
        }

        this.cdr.detectChanges();

        this.agencyForm.valueChanges.subscribe(() => {
          this.hasUnsavedFormChanges = true;
        });
        this.agencyForm
          .get('featureSettings')
          ?.get('videoblocks')
          ?.valueChanges.subscribe(() => {
            this.hasUnsavedPremiumChanges = true;
          });
        this.agencyForm
          .get('featureSettings')
          ?.get('getty')
          ?.valueChanges.subscribe(() => {
            this.hasUnsavedPremiumChanges = true;
          });
        this.agencyForm.get('featureSettings')?.valueChanges.subscribe(() => {
          this.hasUnsavedPremiumChanges = true;
        });
        this.agencyForm
          .get('featureSettings')
          ?.get('renderCredits')
          ?.valueChanges.subscribe(() => {
            this.hasUnsavedFreemiumChanges = true;
          });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setAgency(agency: Agency): void {
    if (agency?.customerType === CustomerTypes.FREEMIUM) {
      this.isFreemiumAgency = true;
      this.maxCustomFonts = agency?.featureAccess?.maxCustomFont?.value;
      this.patchPremiumValues('premiumMusic', false, false);
    }

    this.setPremiumValues(agency.customerType);
    this.updateAgencyFormFields(agency);

    const freeTrialExpirationDate = agency.freeTrialExpirationDate;
    const hasFreeTrialExpirationDate = !!freeTrialExpirationDate;
    this.agencyForm.controls.freeTrial.setValue(hasFreeTrialExpirationDate);
    if (hasFreeTrialExpirationDate) {
      this.agencyForm.controls.freeTrialExpirationDate.setValue(
        freeTrialExpirationDate
      );
      if (new Date(freeTrialExpirationDate) < new Date() && !agency.active) {
        this.hasExpiredTrial = true;
      }
      if (this.hasExpiredTrial) {
        this.agencyForm.disable();
      }
    }
  }

  initCustomerTypeOptions() {
    this.mode$.pipe(first()).subscribe((mode) => {
      if (mode === Modes.Edit) {
        if (!this.customerTypeOptions.includes(CustomerTypes.STANDARD)) {
          this.customerTypeOptions.push(CustomerTypes.STANDARD);
        }
        if (!this.customerTypeOptions.includes(CustomerTypes.STARTER)) {
          this.customerTypeOptions.push(CustomerTypes.STARTER);
        }
      }
    });
    this.featureService
      .isFeatureEnabledSubject(FEATURE.TIME_BASED_FREE_TRIAL)
      .pipe(takeUntil(this.destroy$))
      .subscribe((useTimeBasedFreeTrial) => {
        if (useTimeBasedFreeTrial) {
          if (!this.customerTypeOptions.includes(CustomerTypes.LITE)) {
            this.customerTypeOptions.push(CustomerTypes.LITE);
          }
        }
      });
  }

  fontChangesHandler(fonts: any) {
    if (fonts) {
      this.hasUnsavedFontChanges = true;
    } else {
      this.hasUnsavedFontChanges = false;
    }
  }

  updateMaxCustomFonts(amount: number) {
    if (this.maxCustomFonts !== undefined) {
      const newAmount = this.maxCustomFonts + amount;
      this.agencyForm
        .get('featureSettings')
        ?.get('maxCustomFont')
        ?.patchValue({ value: newAmount, disabled: false });
      this.maxCustomFonts = newAmount;
    }
  }

  loadClients(): void {
    this.agencyId$
      .pipe(
        take(1),
        switchMap((agencyId) => {
          if (!agencyId) {
            return of([]);
          }
          return this.clientService.getClients(agencyId);
        })
      )
      .subscribe((clients) => {
        this.clientsSubject$.next(clients);
      });
  }

  removeFontLimitIfNotFreemium(): void {
    if (!this.isFreemiumAgency) {
      (this.agencyForm.get('featureSettings') as FormGroup).removeControl(
        'maxCustomFont'
      );
    }
  }

  async createAgency(): Promise<void> {
    try {
      this.formLoading = true;
      this.removeFontLimitIfNotFreemium();
      const agency = await lastValueFrom(
        this.agencyService.createAgency(this.agencyForm.value)
      );
      this.maxCustomFonts =
        this.agencyForm.value.featureSettings?.maxCustomFont?.value;

      this.notification.show('success', AgencyNotification.Created);
      this.cache.removeCacheEntry('clientsAgencies');
      this.router.navigate(['/agencies', agency._id]);
    } catch (error) {
      this.notification.show('error', AgencyNotification.NotCreated);
    }
    this.formLoading = false;
  }

  async updateAgency(): Promise<void> {
    const agencyId = (await firstValueFrom(this.agencyId$)) as string;
    this.formLoading = true;
    let deleteClientsConfirmed = true;
    try {
      this.removeFontLimitIfNotFreemium();
      const agency = { ...this.agencyForm.value, _id: agencyId };
      await lastValueFrom(this.agencyService.updateAgency(agency));

      // Create all clients marked for creation and update the table
      const clientCreateRequests: Promise<Client>[] = [];
      this.clientDataSource
        .filter((clientTableEntry) => clientTableEntry.new)
        .forEach((clientTableEntry) => {
          clientCreateRequests.push(
            lastValueFrom(
              this.clientService.createClient(clientTableEntry.client, agencyId)
            )
          );
        });
      await Promise.all(clientCreateRequests).then((clients) => {
        clients.forEach((client) => {
          this.clientDataSource.push({
            client,
            deleted: false,
            new: false,
            updated: false,
          });
        });
      });

      this.clientDataSource = this.clientDataSource.filter(
        (clientTableEntry) => !clientTableEntry.new
      );

      const clientUpdateRequests: Promise<Client>[] = [];
      this.clientDataSource
        .filter((clientTableEntry) => clientTableEntry.updated)
        .forEach((clientTableEntry) => {
          clientUpdateRequests.push(
            lastValueFrom(
              this.clientService.updateClient(
                clientTableEntry.client._id,
                clientTableEntry.client
              )
            )
          );
        });
      await Promise.all(clientUpdateRequests);

      // Delete all clients marked for deletion and update the table
      const deleteClientIds: string[] = [];

      this.clientDataSource
        .filter((clientTableEntry) => clientTableEntry.deleted)
        .forEach((clientTableEntry) =>
          deleteClientIds.push(clientTableEntry.client._id)
        );

      if (deleteClientIds.length) {
        const dialogRef = ConfirmDialogComponent.open(this.dialog, {
          action: 'Delete',
          subject: 'clients',
          amount: deleteClientIds.length,
        });
        deleteClientsConfirmed = await lastValueFrom(dialogRef.afterClosed());

        if (deleteClientsConfirmed) {
          for (const clientId of deleteClientIds) {
            await lastValueFrom(this.clientService.deleteClient(clientId));
          }
          this.clientDataSource = this.clientDataSource.filter(
            (clientTableEntry) => !clientTableEntry.deleted
          );
          this.cache.removeCacheEntry('clientsAgencies');
        } else {
          this.clientDataSource = this.clientDataSource.map((value) => {
            value.deleted = false;
            return value;
          });
        }
      }

      if (clientCreateRequests.length || clientUpdateRequests.length) {
        this.cache.removeCacheEntry('clientsAgencies');
      }

      this.clientTable?.renderRows();

      this.fonts$ = this.cwsService.listFonts(agencyId);
      this.maxCustomFonts =
        this.agencyForm.value.featureSettings?.maxCustomFont?.value;

      this.updateAgencyFormFields(agency);
      this.resetFlags();

      this.notification.show(
        'success',
        `${AgencyNotification.Updated} ${deleteClientsConfirmed ? '' : `, ${AgencyNotification.ClientsNotDeleted}`}`
      );
    } catch (error) {
      this.notification.show('error', AgencyNotification.NotUpdated);
    }

    this.loadClients();

    this.formLoading = false;
  }

  resetFlags(): void {
    this.hasUnsavedClientChanges = false;
    this.hasUnsavedPremiumChanges = false;
    this.hasUnsavedFreemiumChanges = false;
    this.hasUnsavedFontChanges = false;
    this.hasUnsavedFormChanges = false;
  }

  async openDeleteDialog(): Promise<void> {
    const dialogRef = ConfirmDialogComponent.open(this.dialog, {
      action: 'Delete',
      subject: 'agency',
    });
    const confirmed = await lastValueFrom(dialogRef.afterClosed());

    if (confirmed) {
      await this.deleteAgency();
    }
  }

  async openRestartTrialDialog(): Promise<void> {
    const dialogRef = ConfirmDialogComponent.open(this.dialog, {
      action: 'Reactivate',
      subject: 'agency',
    });
    const confirmed = await lastValueFrom(dialogRef.afterClosed());

    if (confirmed) {
      await this.restartTrial();
    }
  }

  // Reset client input field error if left without a value
  clientInputBlur(): void {
    if (!this.clientForm.get('name')?.value) {
      this.clientForm.markAsUntouched();
    }
  }

  saveClient(
    clientTableEntry: ClientTableEntry,
    formName: string,
    index: number
  ) {
    const itemToUpdate = this.clientDataSource[index];
    itemToUpdate.client.hubspotCompanyId =
      this.clientDataForm.get(formName)?.value;
    itemToUpdate.updated = true;
    this.hasUnsavedClientChanges = true;
  }

  addClient() {
    const defaultHubspotValue = this.agencyForm.get(
      this.clientFormFields.hubspotCompanyId
    )?.value;
    this.clientDataForm.addControl(
      this.clientFormFields.hubspotCompanyId + this.clientDataSource.length,
      new FormControl(defaultHubspotValue, Validators.required)
    );
    this.clientDataSource.push({
      client: {
        name: this.clientForm.value.name,
        hubspotCompanyId: defaultHubspotValue,
      } as Client,
      deleted: false,
      new: true,
      updated: false,
    });
    this.clientForm.reset();
    this.clientTable?.renderRows();
    this.hasUnsavedClientChanges = true;
  }

  async deleteClient(
    clientTableEntry: ClientTableEntry,
    index: number
  ): Promise<void> {
    const dialogRef = ConfirmDialogComponent.open(this.dialog, {
      action: 'Delete',
      subject: 'client',
      name: clientTableEntry.client.name,
    });
    const confirmed = await lastValueFrom(dialogRef.afterClosed());

    if (!confirmed) return;

    // Remove yet to be created clients from the data source
    if (clientTableEntry.new) {
      this.clientDataSource.splice(index, 1);
      this.clientTable?.renderRows();
      if (
        this.clientDataSource.some(
          (client) => client.new || client.updated || client.deleted
        )
      ) {
        this.hasUnsavedClientChanges = true; // if a new client has been created or an existing client has been updated/deleted
      } else {
        this.hasUnsavedClientChanges = false;
      }
      return;
    }

    // Show unsaved changes if client already in the data source is deleted before updating agency
    clientTableEntry.deleted = true;
    this.hasUnsavedClientChanges = true;
  }

  generateClientLinkCreate(clientTableEntry: ClientTableEntry) {
    const clientId = clientTableEntry.client._id;
    return `${this.createUrl}/clients/${clientId}`;
  }

  private async deleteAgency(): Promise<void> {
    const agencyId = (await firstValueFrom(this.agencyId$)) as string;

    try {
      await lastValueFrom(this.agencyService.deleteAgency(agencyId));
      this.notification.show('success', AgencyNotification.Deleted);
      this.router.navigate(['/agencies']);
    } catch (error) {
      this.notification.show('error', AgencyNotification.NotDeleted);
    }
  }

  private async restartTrial(): Promise<void> {
    const agency = (await firstValueFrom(this.agency$)) as Agency;

    try {
      const restartTrialResponse = await lastValueFrom(
        this.cwsService.restartTrial(agency._id)
      );

      this.notification.show('success', AgencyNotification.RestartedTrial);
      if (restartTrialResponse) {
        const updatedAgency = {
          ...agency,
          freeTrialExpirationDate: restartTrialResponse.freeTrialExpirationDate,
        };
        this.agencyForm.enable();
        this.hasExpiredTrial = false;
        this.setAgency(updatedAgency);
        this.resetFlags();
      }
    } catch (error) {
      this.notification.show('error', AgencyNotification.NotRestartedTrial);
    }
  }

  private updateAgencyFormFields(agency: Agency) {
    this.agencyForm.patchValue(agency);
    this.agencyForm.controls.featureSettings.patchValue(agency.featureAccess);
  }

  private freeTrialExpirationDateValidator(
    formGroup: FormGroup<FreeTrialForm>
  ): ValidationErrors | null {
    if (formGroup.value.freeTrial) {
      return Validators.required(
        formGroup.get('freeTrialExpirationDate') as AbstractControl
      );
    }

    return null;
  }

  private setPremiumValues(customerType: string) {
    switch (customerType) {
      case CustomerTypes.LITE:
        this.patchPremiumValues('premiumMusic', false, true);
        this.patchPremiumValues('videoblocks', false, true);
        this.patchPremiumValues('getty', false, true);
        this.patchPremiumValues('scriptCreators', true, false);
        break;
      case CustomerTypes.PRO:
        this.patchPremiumValues('premiumMusic', true, true);
        this.patchPremiumValues('videoblocks', true, false);
        this.patchPremiumValues('getty', false, true);
        this.patchPremiumValues('scriptCreators', true, true);
        break;
      case CustomerTypes.ENTERPRISE:
        this.patchPremiumValues('premiumMusic', true, true);
        this.patchPremiumValues('videoblocks', true, false);
        this.patchPremiumValues('getty', true, true);
        this.patchPremiumValues('scriptCreators', true, true);
        break;
      /** @deprecated - freemium is deprecated */
      case CustomerTypes.FREEMIUM:
        this.patchPremiumValues('premiumMusic', false, false);
        this.patchPremiumValues('videoblocks', false, false);
        this.patchPremiumValues('getty', false, false);
        this.patchPremiumValues('scriptCreators', true, true);
        break;
      /** @deprecated - starter is deprecated */
      case CustomerTypes.STARTER:
        this.patchPremiumValues('premiumMusic', false, true);
        this.patchPremiumValues('videoblocks', false, true);
        this.patchPremiumValues('getty', false, true);
        this.patchPremiumValues('scriptCreators', true, true);
        break;
      /** @deprecated - standard is deprecated */
      case CustomerTypes.STANDARD:
        this.patchPremiumValues('premiumMusic', false, true);
        this.patchPremiumValues('videoblocks', true, false);
        this.patchPremiumValues('getty', false, true);
        this.patchPremiumValues('scriptCreators', true, true);
        break;
      default:
        break;
    }
  }

  private patchPremiumValues(
    formField: string,
    enabledFeature: boolean,
    enabledForm: boolean
  ) {
    this.agencyForm.controls.featureSettings
      .get(formField)
      ?.patchValue({ value: enabledFeature });
    if (enabledForm) {
      this.agencyForm.controls.featureSettings.get(formField)?.enable();
    } else {
      this.agencyForm.controls.featureSettings.get(formField)?.disable();
    }
  }
}
